import React, { ReactElement, ReactNode } from 'react';
import Layout from 'components/layout';
import PageHeader from 'components/pageheader';
import Container from 'components/ui/container';
import { graphql } from 'gatsby';
import { IGatsbyImageData, GatsbyImage } from 'gatsby-plugin-image';
import { useIntl } from 'react-intl';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
//import ContactBanner from 'components/contactbanner';

export default function ProductsSingleTemplate({
    data,
}: {
    data: GatsbyTypes.AboutPageQuery;
}) {
    if (!data.about) {
        return (
            <Layout
                seo={{ title: 'Not translated' }}
                showNavbarPlaceholder={true}
            >
                <div>This pages has not been translated yet.</div>
            </Layout>
        );
    }

    const intl = useIntl();

    const { about } = data;

    return (
        <Layout
            seo={{
                title: intl.formatMessage({
                    id: 'aboutUs',
                }),
                image: about.frontmatter?.banner?.publicURL as string,
            }}
        >
            <PageHeader
                title={intl.formatMessage({
                    id: 'aboutUs',
                })}
                image={
                    (about.frontmatter?.banner?.childImageSharp
                        ?.gatsbyImageData as unknown) as IGatsbyImageData
                }
            />
            <Container>
                <div className="py-12 flex flex-wrap items-center">
                    
                    <div className="w-full lg:w-3/5 page">
                        <MDXProvider
                            components={{
                                Services,
                                Service,
                            }}
                        >
                            <MDXRenderer>{about.body}</MDXRenderer>
                        </MDXProvider>
                        <div className="w-1/2 lg:w-1/3">
                            <GatsbyImage
                                image={
                                    (about.frontmatter?.iso?.childImageSharp
                                        ?.gatsbyImageData as unknown) as IGatsbyImageData
                                }
                                alt="iso9001-2015"
                            />
                        </div>
                        
                    </div>
                    <div className="lg:block w-full lg:w-2/5 p-3">
                        <GatsbyImage
                            image={
                                (about.frontmatter?.image?.childImageSharp
                                    ?.gatsbyImageData as unknown) as IGatsbyImageData
                            }
                            alt=""
                        />
                    </div>
                </div>
            </Container>
        </Layout>
    );
}

const Services = ({ children }: { children: ReactNode }) => {
    return <div className="flex flex-wrap py-8">{children}</div>;
};

const Service = ({
    icon,
    title,
    children,
}: {
    icon: ReactElement;
    title: string;
    children: ReactNode;
}) => {
    return (
        <>
        </>
    );
};

export const query = graphql`
    query AboutPage($locale: String!) {
        about: mdx(
            fields: { slug: { eq: "/about" }, locale: { eq: $locale } }
        ) {
            fields {
                locale
            }
            frontmatter {
                title
                banner {
                    publicURL
                    childImageSharp {
                        gatsbyImageData
                    }
                }
                image {
                    publicURL
                    childImageSharp {
                        gatsbyImageData
                    }
                }
                iso {
                    publicURL
                    childImageSharp {
                        gatsbyImageData
                    }
                }
            }
            body
        }
    }
`;
